// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blockchain-js": () => import("./../src/pages/blockchain.js" /* webpackChunkName: "component---src-pages-blockchain-js" */),
  "component---src-pages-consulting-js": () => import("./../src/pages/consulting.js" /* webpackChunkName: "component---src-pages-consulting-js" */),
  "component---src-pages-elements-js": () => import("./../src/pages/elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-engineering-js": () => import("./../src/pages/engineering.js" /* webpackChunkName: "component---src-pages-engineering-js" */),
  "component---src-pages-generic-js": () => import("./../src/pages/generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-landing-js": () => import("./../src/pages/landing.js" /* webpackChunkName: "component---src-pages-landing-js" */),
  "component---src-pages-references-js": () => import("./../src/pages/references.js" /* webpackChunkName: "component---src-pages-references-js" */),
  "component---src-pages-visual-js": () => import("./../src/pages/visual.js" /* webpackChunkName: "component---src-pages-visual-js" */),
  "component---src-pages-webdev-js": () => import("./../src/pages/webdev.js" /* webpackChunkName: "component---src-pages-webdev-js" */)
}

